import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { Observable } from 'rxjs';

import { APP_CONFIG } from '@@shared/providers/application-config-provider/application-config-provider.model';

import { ResponseWrapper } from '../models/api.model';
import {
	GlobalTestStagesV1RequestParams,
	TestStageGlobalV1
} from '../models/app-branch-build-teststage.model';

@Injectable({
	providedIn: 'root'
})
export class AppBranchBuildTeststageService {
	readonly #apiBaseUrl = inject(APP_CONFIG)?.uri.apiBaseUrl;
	readonly #httpClient = inject(HttpClient);

	getGlobalTestStagesV1(params: GlobalTestStagesV1RequestParams): Observable<ResponseWrapper<TestStageGlobalV1>> {
		let httpParams = new HttpParams();

		httpParams = httpParams.append('appName', params.appName);

		if (params.branchName) {
			httpParams = httpParams.append('branchName', params.branchName);
		}

		if (Number.isFinite(params.periodFrom)) {
			httpParams = httpParams.append('periodFrom', params.periodFrom.toString());
		}

		if (Number.isFinite(params.periodTo)) {
			httpParams = httpParams.append('periodTo', params.periodTo.toString());
		}

		if (params.bsid) {
			httpParams = httpParams.append('bsid', params.bsid);
		}

		if (params.withIntegrationStages) {
			httpParams = httpParams.append('withIntegrationStages', params.withIntegrationStages);
		}

		const url = `${this.#apiBaseUrl}v1/global-test-stages`;

		return this.#httpClient.get<ResponseWrapper<TestStageGlobalV1>>(url, { params: httpParams });
	}

	getTestStagesV2(appName: string, branchName?: string, bsid?: string, periodFrom?: number, periodTo?: number): Observable<string[]> {
		let httpParams = new HttpParams();

		httpParams = httpParams.append('appName', appName);

		if (!isNil(branchName)) {
			httpParams = httpParams.append('branchName', branchName);
		}

		if (Number.isFinite(periodFrom)) {
			httpParams = httpParams.append('periodFrom', periodFrom.toString());
		}

		if (Number.isFinite(periodTo)) {
			httpParams = httpParams.append('periodTo', periodTo.toString());
		}

		if (!isNil(bsid)) {
			httpParams = httpParams.append('bsid', bsid);
		}

		const url = `${this.#apiBaseUrl}v2/test-stages`;

		return this.#httpClient.get<string[]>(url, { params: httpParams });
	}
}
